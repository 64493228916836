import getLiveGameUniqueId from '@tlf-e/brand-utils/dist/games/getLiveGameUniqueId';
import GameListItem from '../../casino-games/fragments/list/item';

const LobbyGamesList = ({ gameList, gamesLimit, isJackpot }) => {
    return (
        <div className="game-list">
            {gameList.map((game, index) => {
                return (
                    <GameListItem
                        isJackpot={isJackpot}
                        game={game}
                        dataId={getLiveGameUniqueId(game)}
                        key={`${game.id}_${index}`}
                    />
                );
            })}
            {[...new Array(gamesLimit - gameList.slice(0, gamesLimit).length)].map((item, i) => (
                <div className="game-list-item" key={`plug_${i}`} />
            ))}
        </div>
    );
};

export default LobbyGamesList;
