import { Fragment } from 'react';

const BannerVideo = ({ video_fallback_img, video_webm, video_mp4, data_testid = '' }) => {
    return (
        <Fragment>
            <video
                className="banner--bg"
                autoPlay
                playsInline
                loop
                muted
                poster={video_fallback_img}
                data-testid={data_testid}
            >
                {video_webm && <source src={video_webm} type="video/webm" />}
                {video_mp4 && <source src={video_mp4} type="video/mp4" />}
                <img src={video_fallback_img} alt="Browser does not support video tag" />
            </video>
        </Fragment>
    );
};

export default BannerVideo;
