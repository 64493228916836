import { useSelector } from 'react-redux';
import Link from 'next/link';
import PromotionItem from '../../../../common/promotions/PromotionItem';
import FormLoader from '../../../../common/FormLoader';
import Carousel from '../../../../ui/Carousel';
import { useDisplayPromotions } from '../../../../../hooks/useDisplayPromotions';
import routes from '../../../../../data/routes.json';

const LobbyPromotions = ({ tr }) => {
    const paths = useSelector((state) => state.global.paths);
    const { promotionsList, isLoading } = useDisplayPromotions(['displayOnLobby']);

    if (isLoading) {
        return <FormLoader />;
    }

    if (!promotionsList.length) {
        return null;
    }

    return (
        <div className="lobby--promotions">
            <div className="title">
                <div className="name">{tr['nav.promotions']}</div>
                <Link href={routes.promotions} as={paths[routes.promotions]}>
                    <a>{tr['see_all']}</a>
                </Link>
            </div>
            <Carousel>
                {promotionsList.map((item, index) => (
                    <PromotionItem key={index} item={item} />
                ))}
            </Carousel>
        </div>
    );
};

export default LobbyPromotions;
