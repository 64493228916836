import { useTopGames } from '../../../../../hooks/useTopGames';
import GameListItem from '../../../casino-games/fragments/list/item';

const LobbyTopGames = ({ tr, gamesLimit }) => {
    const trendingGames = useTopGames(gamesLimit);

    return (
        <div className="games-lobby--latest">
            <div className="title">
                <div className="name">{tr[`games.top_games.title`]}</div>
            </div>
            <div className="game-list">
                <div className="scroll-container">
                    {trendingGames.map((game) => (
                        <GameListItem game={game} key={game.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LobbyTopGames;
