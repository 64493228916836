import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import Carousel from '../../ui/Carousel';
import FormLoader from '../FormLoader';
import ClaimedBonusItem from '../bonuses/fragments/claimed/ClaimedBonusItem';
import OfferedBonusItem from '../bonuses/fragments/offered/OfferedBonusItem';
import CancelClaimedBonusModal from '../bonuses/fragments/claimed/CancelClaimedBonusModal';
import { withBonuses } from '../../hoc/withBonuses';
import { setModalCancelBonusId } from '../../../store/reducers/bonus';
import routes from '../../../data/routes.json';
import CancelClaimedBonusErrorModal from '../bonuses/fragments/claimed/CancelClaimedBonusErrorModal';

const BonusCarousel = ({ hasBonuses, allOfferedBonuses, allClaimedBonuses, tr, handleCancel, isLoading, ...props }) => {
    const paths = useSelector((state) => state.global.paths);
    const { claimBonusId, removeBonusId, removeBonusFailed } = useSelector((state) => state.bonus);
    const dispatch = useDispatch();
    const claimedLimit = 5;
    const claimedBonuses = allClaimedBonuses.slice(0, claimedLimit);
    const restLimit = claimedLimit - claimedBonuses.length;
    const offeredLimit = restLimit > 0 ? restLimit : 0;
    const offeredBonuses = allOfferedBonuses.slice(0, offeredLimit);

    if (isLoading) {
        return <FormLoader />;
    }

    if (!hasBonuses) {
        return null;
    }

    return (
        <div className="bonus-carousel-container">
            <div className="title">
                <h1 className="name">{tr['lobby.bonuses.title']}</h1>
                <Link href={routes.bonuses} as={paths[routes.bonuses]}>
                    <a>{tr['see_all']}</a>
                </Link>
            </div>
            <Carousel extraClass="bonus-carousel">
                {claimedBonuses.map((bonus) => (
                    <ClaimedBonusItem
                        {...props}
                        key={bonus.id}
                        tr={tr}
                        bonus={bonus}
                        removeBonusId={removeBonusId}
                        openCancelBonusModal={() => dispatch(setModalCancelBonusId(bonus.id))}
                    />
                ))}
                {offeredBonuses.map((bonus) => (
                    <OfferedBonusItem {...props} key={bonus.id} tr={tr} bonus={bonus} claimBonusId={claimBonusId} />
                ))}
            </Carousel>
            <CancelClaimedBonusModal tr={tr} handleCancel={handleCancel} />
            {removeBonusFailed && <CancelClaimedBonusErrorModal tr={tr} removeBonusFailed={removeBonusFailed} />}
        </div>
    );
};

export default withBonuses(BonusCarousel);
