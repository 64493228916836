import { useSelector } from 'react-redux';
import { getServerSideProps } from '../utils/getServerSideProps/full-access-page';
import Head from '../components/ui/Head';
import Layout from '../components/Layout';
import LobbyDesktop from '../components/desktop/lobby';
import LobbyMobile from '../components/mobile/lobby';

const LobbyPage = ({ device = {} }) => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <Layout device={device}>
            <Head title={tr['title.home']} description={tr['meta.description.home']} />
            {device.isMobile ? <LobbyMobile device={device} /> : <LobbyDesktop />}
        </Layout>
    );
};

export { getServerSideProps };

export default LobbyPage;
