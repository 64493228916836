import { useSelector } from 'react-redux';
import Banner from './Banner';
import FormLoader from '../FormLoader';
import { imageBaseUrl } from '../../../services/http';
import Carousel from '../../ui/Carousel';
import cx from 'classnames';

const WelcomeBanner = ({ tr }) => {
    const { isVip, information, isAuth } = useSelector((state) => state.user);
    const { sliderIsLoaded } = useSelector((state) => state.slider);
    const { slider } = useSelector((state) => state.slider.data);
    const showCarousel = slider.length > 1;
    const vipRank = information.vipLevel?.rank;

    return (
        <div>
            <div className="title title-welcome">
                <h1 className="name">
                    {isAuth && isVip && vipRank && (
                        <img src={`${imageBaseUrl}/vip/level${vipRank}.png`} alt="crown_vip" />
                    )}
                    {isAuth && information.firstName ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: tr['lobby.welcome_banner.auth_title']?.replace('##NAME##', information.firstName)
                            }}
                        />
                    ) : (
                        <span>{tr['lobby.welcome_banner.default_title']}</span>
                    )}
                </h1>
            </div>
            <div
                className={cx({
                    'welcome-banner': !showCarousel,
                    'welcome-banner-carousel': showCarousel
                })}
            >
                {sliderIsLoaded ? (
                    showCarousel ? (
                        <Carousel extraClass="banner-carousel" autoPlay interval={10000}>
                            {slider.map((item, index) => (
                                <Banner key={index} {...item} />
                            ))}
                        </Carousel>
                    ) : (
                        <Banner {...slider[0]} />
                    )
                ) : (
                    <FormLoader />
                )}
            </div>
        </div>
    );
};

export default WelcomeBanner;
