import { Fragment } from 'react';
import GameListItem from '../../../casino-games/fragments/list/item';
import LatestReleases from './LatestReleases';
import { useAnimatedThumbnail } from '../../../../../hooks/useAnimatedThumbnail';
import LobbyTopGames from './LobbyTopGames';

const LobbyGames = ({ tr, gamesLimit }) => {
    const game = useAnimatedThumbnail();

    return (
        <div className="lobby--games">
            {game && (
                <Fragment>
                    <div className="title">
                        <div className="name">{tr['lobby.games.hot_weekly_release']}</div>
                    </div>
                    <div className="games-lobby--featured">
                        <GameListItem game={game} isFeatured />
                    </div>
                </Fragment>
            )}
            <LatestReleases tr={tr} gamesLimit={gamesLimit} />
            <LobbyTopGames tr={tr} gamesLimit={gamesLimit} />
        </div>
    );
};

export default LobbyGames;
