import { useSelector } from 'react-redux';
import Link from 'next/link';
import GameListItem from '../../../casino-games/fragments/list/item';
import JackpotBanner from '../../../../common/jackpot/fragments/JackpotBanner';
import useJackpotGames from '../../../../../hooks/useJackpotGames';
import routes from '../../../../../data/routes.json';
import JackpotFailed from '../../../../common/jackpot/fragments/JackpotFailed';
import { Fragment } from 'react';

const LobbyJackpot = ({ tr }) => {
    const { jackpotGames, claimWazdanFailed } = useJackpotGames();
    const paths = useSelector((state) => state.global.paths);

    return (
        <div className="games-lobby--latest jackpot-lobby-wrapper">
            <div className="title">
                <div className="name">{tr['title.jackpot']}</div>
                <Link href={routes.jackpot_games} as={paths[routes.jackpot_games]}>
                    <a>{tr['see_all']}</a>
                </Link>
            </div>
            {claimWazdanFailed ? (
                <JackpotFailed tr={tr} isLobby />
            ) : (
                <Fragment>
                    <div className="jackpot-banner-container">
                        <JackpotBanner />
                    </div>
                    <div className="game-list">
                        <div className="scroll-container">
                            {jackpotGames.map((game) => (
                                <GameListItem game={game} key={game.id} isJackpot />
                            ))}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default LobbyJackpot;
