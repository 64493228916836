import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import getContentGamesIds from '@tlf-e/brand-utils/dist/games/gamesCommon/getContentGamesIds';
import { RECOMMENDED_GAMES_SLUG } from '../variables';

export const useRecommendedGames = () => {
    const { games } = useSelector((state) => state.games.data);
    const cmsPages = useSelector((state) => state.global.data.cmsPages);

    const recommendedGames = useMemo(() => {
        const contentGames = getContentGamesIds(cmsPages, RECOMMENDED_GAMES_SLUG);
        const recommendedGames = contentGames.reduce((acc, { gameId, ...item }) => {
            const game = games.find((game) => game.id === +gameId);
            return game
                ? [
                    ...acc,
                    {
                        ...game,
                        ...item
                    }
                ]
                : [...acc];
        }, []);
        return recommendedGames;
    }, [games, cmsPages]);

    return recommendedGames;
};
