import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import extendContentGames from '@tlf-e/brand-utils/dist/games/gamesCommon/extendContentGames';
import getContentGames from '@tlf-e/brand-utils/dist/games/gamesCommon/getContentGames';
import { sortGamesByKey } from '../utils/games';
import { RECENT_GAMES_SLUG, GAMES_SORT_KEY_MOST_RECENT } from '../variables';

export const useLatestGames = (limit = 15) => {
    const { games } = useSelector((state) => state.games.data);
    const cmsPages = useSelector((state) => state.global.data.cmsPages);

    const latestGames = useMemo(() => {
        const contentGames = getContentGames(cmsPages, RECENT_GAMES_SLUG, games);
        const allGames = sortGamesByKey(GAMES_SORT_KEY_MOST_RECENT, games);
        const latestGamesList = extendContentGames(contentGames, allGames, limit);
        return latestGamesList;
    }, [games, cmsPages, limit]);

    return latestGames;
};
