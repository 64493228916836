import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import GameListItem from '../../casino-games/fragments/list/item';
import { useLiveGames } from '../../../../hooks/useLiveGames';
import { getLiveTables } from '../../../../utils/games';
import routes from '../../../../data/routes.json';
import { FILTER_LIVE_MOST_POPULAR } from '../../../../variables';

const MostPopularLiveGames = ({ tr, thumbnailLimit, isLiveGamesLobby }) => {
    const paths = useSelector((state) => state.global.paths);
    const { liveGamesLists, allLiveGames } = useLiveGames();
    const mostPlayedLiveGames = useMemo(
        () => getLiveTables(allLiveGames, liveGamesLists, FILTER_LIVE_MOST_POPULAR).slice(0, thumbnailLimit),
        [allLiveGames, liveGamesLists]
    );
    const [primaryLive, ...restMostPlayedLiveGames] = mostPlayedLiveGames;

    return (
        <div className="top-game-list top-margin--medium">
            <div className="title">
                <div className="name">{tr[`live_games.category.${FILTER_LIVE_MOST_POPULAR}`]}</div>
                {isLiveGamesLobby && (
                    <Link href={routes.live_casino_games} as={paths[routes.live_casino_games]}>
                        <a>{tr['see_all']}</a>
                    </Link>
                )}
            </div>
            {primaryLive && (
                <div className="games-lobby--featured">
                    <GameListItem dataId={primaryLive.uniqueTableId} game={primaryLive} isFeatured />
                </div>
            )}
            <div className="game-list">
                <div className="scroll-container">
                    {restMostPlayedLiveGames.map((item, index) => {
                        return <GameListItem dataId={item.uniqueTableId} key={`${item.id}_${index}`} game={item} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default MostPopularLiveGames;
