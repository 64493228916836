import { useSelector } from 'react-redux';
import { PROMO_STATUS_EXPIRED, PROMO_STATUS_ONGOING } from '../../../variables';

const PromoStatusPlate = ({ status }) => {
    const tr = useSelector((state) => state.global.data.translations);

    if (![PROMO_STATUS_ONGOING, PROMO_STATUS_EXPIRED].includes(status)) {
        return null;
    }

    return (
        <span className={`promo-plate-status promo-plate-status--${status}`}>
            {status === PROMO_STATUS_ONGOING && <span className="pulse-dot" />}
            <span>{tr[`promotions.status.${status}`]}</span>
        </span>
    );
};

export default PromoStatusPlate;
