import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import getLiveGameUniqueId from '@tlf-e/brand-utils/dist/games/getLiveGameUniqueId';
import GameListItem from '../../casino-games/fragments/list/item';
import { useLiveGames } from '../../../../hooks/useLiveGames';
import { getLiveTables } from '../../../../utils/games';
import { FILTER_LIVE_MOST_POPULAR } from '../../../../variables';
import routes from '../../../../data/routes.json';

const MostPopularLiveGames = ({ tr, thumbnailLimit, isLiveGamesLobby }) => {
    const paths = useSelector((state) => state.global.paths);
    const { liveGamesLists, allLiveGames } = useLiveGames();
    const mostPopularLiveGames = useMemo(
        () => getLiveTables(allLiveGames, liveGamesLists, FILTER_LIVE_MOST_POPULAR).slice(0, thumbnailLimit),
        [allLiveGames, liveGamesLists]
    );

    return (
        <div>
            <div className="title">
                <h1 className="name">{tr[`live_games.category.${FILTER_LIVE_MOST_POPULAR}`]}</h1>
                {isLiveGamesLobby && (
                    <Link href={routes.live_casino_games} as={paths[routes.live_casino_games]}>
                        <a className="btn btn--small btn--primary">{tr['see_all']}</a>
                    </Link>
                )}
            </div>
            <div className="game-list live-lobby live-lobby--most-played">
                {mostPopularLiveGames.map((game, index) => {
                    return (
                        <GameListItem
                            game={game}
                            isLargeBg={index === 0}
                            dataId={getLiveGameUniqueId(game)}
                            key={`${game.id}_${index}`}
                        />
                    );
                })}
                {[...new Array(thumbnailLimit - mostPopularLiveGames.slice(0, thumbnailLimit).length)].map((item, i) => (
                    <div className="game-list-item" key={`plug_${i}`} />
                ))}
            </div>
        </div>
    );
};

export default MostPopularLiveGames;
