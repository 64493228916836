import { useTopGames } from '../../../../../hooks/useTopGames';
import LobbyGamesList from '../LobbyGamesList';

const LobbyTopGames = ({ tr, defaultGamesLimit }) => {
    const topGames = useTopGames(defaultGamesLimit);

    return (
        <div className="lobby--games tabs-only">
            <div className="title">
                <h1 className="name">{tr[`games.top_games.title`]}</h1>
            </div>
            <div className="games-lobby--latest">
                <LobbyGamesList gameList={topGames} gamesLimit={defaultGamesLimit} />
            </div>
        </div>
    );
};

export default LobbyTopGames;
