import Link from 'next/link';
import { useSelector } from 'react-redux';
import { getModalUrl } from '../../../utils/locationConfig';
import { useRouter } from 'next/router';
import PromoStatusPlate from './PromoStatusPlate';
import PromotionDaysOfWeek from './PromotionDaysOfWeek';

const PromotionItem = ({ item }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const router = useRouter();

    return (
        <Link {...getModalUrl(item.slug, router, 'promo')} scroll={false}>
            <a className="promotion">
                <div className="visual">
                    <picture>
                        <source srcSet={item.backgroundWEBP} type="image/webp" />
                        <img src={item.background} alt={item?.title} />
                    </picture>
                    <div className="promo-plate-list">
                        <PromoStatusPlate status={item.status} />
                        {item.tags?.map((item, index) => (
                            <span key={index} className="promo-plate-status promo-plate-status--tag">
                                <span>{item}</span>
                            </span>
                        ))}
                    </div>
                    {item.daysOfWeek?.length > 0 && (
                        <PromotionDaysOfWeek daysOfWeek={item.daysOfWeek} customClass="promo-days-of-week-wrapper" />
                    )}
                </div>
                <div className="infos">
                    <div className="infos-text-container">
                        <div className="promo-title">{item.title}</div>
                        <div className="subtitle">{item.subtitle}</div>
                    </div>
                    <button className="btn btn-submit btn-modern">{tr['btn.discover']}</button>
                </div>
            </a>
        </Link>
    );
};

export default PromotionItem;
