import GameListItem from '../../../casino-games/fragments/list/item';
import { useLatestGames } from '../../../../../hooks/useLatestGames';

const LatestReleases = ({ tr, gamesLimit }) => {
    const latestGames = useLatestGames(gamesLimit);

    return (
        <div className="games-lobby--latest">
            <div className="title">
                <div className="name">{tr['lobby.games.latest_releases']}</div>
            </div>
            <div className="game-list">
                <div className="scroll-container">
                    {latestGames.map((game) => (
                        <GameListItem game={game} key={game.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LatestReleases;
