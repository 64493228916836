import FormLoader from '../../../../common/FormLoader';
import GameListItem from '../../../casino-games/fragments/list/item';
import { useFavoriteGamesList } from '../../../../../hooks/useFavoriteGamesList';

const LobbyFavorites = ({ tr, gamesLimit }) => {
    const { favoriteGamesLoading, favorites } = useFavoriteGamesList();

    if (favoriteGamesLoading) {
        return <FormLoader extraClass="games-list-loader" />;
    }

    if (!favorites.length) {
        return null;
    }

    return (
        <div className="games-lobby--favorites">
            <div className="title">
                <div className="name">{tr['nav.favorite_games']}</div>
            </div>
            <div className="game-list">
                <div className="scroll-container">
                    {favorites.slice(0, gamesLimit).map((game) => (
                        <GameListItem game={game} key={game.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LobbyFavorites;
