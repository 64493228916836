import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { CURRENT_DEVICE_DESKTOP, CURRENT_DEVICE_MOBILE } from '../../variables';

const deviceProps = {
    [CURRENT_DEVICE_DESKTOP]: {
        emulateTouch: false,
        swipeable: false
    },
    [CURRENT_DEVICE_MOBILE]: {
        emulateTouch: true,
        swipeable: true,
        preventMovementUntilSwipeScrollTolerance: true,
        swipeScrollTolerance: 50
    }
};

const Carousel = ({ children, extraClass = '', ...props }) => {
    const { currentDevice } = useSelector((state) => state.global);

    return (
        <ResponsiveCarousel
            className={cx('carousel-root--modern', { [extraClass]: extraClass })}
            showArrows={false}
            showStatus={false}
            interval={7500}
            showThumbs={false}
            infiniteLoop={true}
            {...deviceProps[currentDevice]}
            {...props}
        >
            {children}
        </ResponsiveCarousel>
    );
};

export default Carousel;
