import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import extendContentGames from '@tlf-e/brand-utils/dist/games/gamesCommon/extendContentGames';
import getContentGames from '@tlf-e/brand-utils/dist/games/gamesCommon/getContentGames';
import { TOP_GAMES_SLUG } from '../variables';

export const useTopGames = (limit = 15) => {
    const { games } = useSelector((state) => state.games.data);
    const cmsPages = useSelector((state) => state.global.data.cmsPages);

    const topGames = useMemo(() => {
        const contentGames = getContentGames(cmsPages, TOP_GAMES_SLUG, games);
        const topGamesList = extendContentGames(contentGames, games, limit);
        return topGamesList;
    }, [games, cmsPages, limit]);

    return topGames;
};
