import { useSelector } from 'react-redux';
import handleValidJson from '@tlf-e/brand-utils/dist/helpers/handleValidJson';
import { useRecommendedGames } from './useRecommendedGames';
import { LOBBY_ANIMATED_THUMBNAIL_SLUG } from '../variables';

export const useAnimatedThumbnail = () => {
    const { cmsPages } = useSelector((state) => state.global.data);
    const { games } = useSelector((state) => state.games.data);
    const recommendedGames = useRecommendedGames();
    const [game] = recommendedGames;
    const content = cmsPages.find((item) => item.slug === LOBBY_ANIMATED_THUMBNAIL_SLUG)?.content;
    const animatedThumbnail = handleValidJson(content) ? JSON.parse(content) : null;
    const gameThumb = games.find((game) => game.id === +animatedThumbnail?.gameId);

    return gameThumb
        ? {
            ...gameThumb,
            ...animatedThumbnail
        }
        : game;
};
