import cx from 'classnames';
import LobbyGamesList from '../LobbyGamesList';
import FormLoader from '../../../../common/FormLoader';
import { useFavoriteGamesList } from '../../../../../hooks/useFavoriteGamesList';

const LobbyFavorites = ({ tr, defaultGamesLimit }) => {
    const { favorites, favoriteGamesLoading } = useFavoriteGamesList();

    if (favoriteGamesLoading) {
        return <FormLoader />;
    }

    if (!favorites.length) {
        return null;
    }

    return (
        <div className="lobby--games tabs-only">
            <div className="title">
                <h1 className="name">{tr[`lobby.games.favorites`]}</h1>
            </div>
            <div
                className={cx('games-lobby--favorites', {
                    extended: favorites.length > 6,
                    'full-row': favorites.length === 6
                })}
            >
                <LobbyGamesList gameList={favorites.slice(0, defaultGamesLimit)} gamesLimit={defaultGamesLimit} />
            </div>
        </div>
    );
};

export default LobbyFavorites;
