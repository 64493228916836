import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useLiveGames = () => {
    const { games } = useSelector((state) => state.games.data);
    const liveGamesLists = useSelector((state) => state.global.data.liveGamesLists);
    const allLiveGames = useMemo(() => games.filter((item) => item.isLive), [games]);

    return {
        liveGamesLists,
        allLiveGames
    };
};
