import cx from 'classnames';
import { PROMOTION_EVERYDAY } from '../../../variables';
import { useSelector } from 'react-redux';

const PromotionDaysOfWeek = ({ daysOfWeek = [], customClass = '' }) => {
    const tr = useSelector((state) => state.global.data.translations);

    const listOfDaysOfWeek =
        daysOfWeek.length === 7
            ? [PROMOTION_EVERYDAY]
            : daysOfWeek.map((day) => tr[`days_of_week.${day.toLowerCase()}`].substring(0, 3));

    return (
        <ul className={cx('promo-days-of-week', { [customClass]: customClass })}>
            {listOfDaysOfWeek.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

export default PromotionDaysOfWeek;
