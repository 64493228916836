import { Fragment } from 'react';
import cx from 'classnames';
import GameListItem from '../../../casino-games/fragments/list/item';
import LobbyGamesList from '../LobbyGamesList';
import { useAnimatedThumbnail } from '../../../../../hooks/useAnimatedThumbnail';
import { useLatestGames } from '../../../../../hooks/useLatestGames';

const LobbyGames = ({ tr, defaultGamesLimit }) => {
    const game = useAnimatedThumbnail();
    const gamesLimit = game ? 8 : defaultGamesLimit;
    const latestGames = useLatestGames(gamesLimit);

    return (
        <div className={cx('lobby--games', { 'tabs-only': !game })}>
            {game && (
                <Fragment>
                    <div className="title">
                        <h1 className="name">{tr['lobby.games.hot_weekly_release']}</h1>
                    </div>
                    <div className="games-lobby--featured">
                        <GameListItem game={game} isFeatured />
                    </div>
                </Fragment>
            )}
            <Fragment>
                <div className="title">
                    <h1 className="name">{tr['lobby.games.latest_releases']}</h1>
                </div>
                <div className="games-lobby--latest">
                    <LobbyGamesList gamesLimit={gamesLimit} gameList={latestGames} />
                </div>
            </Fragment>
        </div>
    );
};

export default LobbyGames;
