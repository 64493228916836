import { useSelector } from 'react-redux';
import WelcomeBanner from '../../../../common/lobby/WelcomeBanner';
import BonusCarousel from '../../../../common/lobby/BonusCarousel';
import { useBonusOfferAllowedData } from '../../../../../hooks/useBonusOfferAllowedData';

const LobbyWelcome = ({ tr }) => {
    const { data, isLoading } = useSelector((state) => state.bonus);
    const { isBonusDataAllowed } = useBonusOfferAllowedData();
    const { allOfferedBonuses, allClaimedBonuses } = data;
    const hasBonuses = [...allOfferedBonuses, ...allClaimedBonuses].length > 0;

    return (
        <div className="lobby--welcome">
            <WelcomeBanner tr={tr} />
            {isBonusDataAllowed && (
                <BonusCarousel
                    tr={tr}
                    isLoading={isLoading}
                    hasBonuses={hasBonuses}
                    allOfferedBonuses={allOfferedBonuses}
                    allClaimedBonuses={allClaimedBonuses}
                />
            )}
        </div>
    );
};

export default LobbyWelcome;
