import MostPopularLiveGames from '../../../live-casino/fragments/MostPopularLiveGames';

const LobbyLives = ({ tr }) => {
    return (
        <div className="lobby--lives">
            <MostPopularLiveGames tr={tr} thumbnailLimit={10} />
        </div>
    );
};

export default LobbyLives;
