import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import routes from '../../../../../data/routes.json';
import JackpotBanner from '../../../../common/jackpot/fragments/JackpotBanner';
import LobbyGamesList from '../LobbyGamesList';
import useJackpotGames from '../../../../../hooks/useJackpotGames';
import JackpotFailed from '../../../../common/jackpot/fragments/JackpotFailed';

const LobbyJackpot = ({ tr }) => {
    const { jackpotGames, claimWazdanFailed } = useJackpotGames();
    const paths = useSelector((state) => state.global.paths);

    return (
        <div className="lobby--games tabs-only">
            <div className="title">
                <h1 className="name">{tr['title.jackpot']}</h1>
                <Link href={routes.jackpot_games} as={paths[routes.jackpot_games]}>
                    <a className="jackpot-games-wrapper__link">{tr['see_all']}</a>
                </Link>
            </div>
            {claimWazdanFailed ? (
                <JackpotFailed tr={tr} isLobby />
            ) : (
                <Fragment>
                    <JackpotBanner />
                    <div className="games-lobby--latest">
                        <LobbyGamesList isJackpot gamesLimit={12} gameList={jackpotGames} />
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default LobbyJackpot;
