import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getPromotionContent } from '../store/reducers/promotions';

export const useDisplayPromotions = (displayOptions = []) => {
    const { allPromotions, isLoading, isLoaded } = useSelector((state) => state.promotions);
    const router = useRouter();
    const dispatch = useDispatch();
    const promotionsList = useMemo(
        () => allPromotions.filter((promo) => displayOptions.some((item) => promo[item])).slice(0, 3),
        [allPromotions]
    );

    useEffect(() => {
        if (!isLoaded) {
            dispatch(getPromotionContent(router.locale));
        }
    }, [isLoaded]);

    return {
        promotionsList,
        isLoading
    };
};
